const open = ref(false)

function openPanel() {
  open.value = true
}

function closePanel() {
  open.value = false
}

export default () => {
  return {
    open,
    openPanel,
    closePanel,
  }
}
