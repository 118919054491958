<template>
  <UiCollapsable
    class="filter-item"
    :active="filter.activeOptionsCount"
    :reference="filter.key"
    :label="$t(filter.label)"
    :open="!!filter.isExpanded"
  >
    <template #icon_before>
      <IconPlus
        class="collapsable__icon icon icon--plus"
      />
    </template>

    <template #icon_after>
      <template v-if="filter.tooltip">
        <button
          type="button"
          class="filter-item__tooltip"
          @click="tooltip.open"
        >
          <IconInfo class="icon icon--tooltip" />
        </button>

        <LazyUiDialog :name="`tooltip__${filter.key}`">
          <template #dialogHeader>
            {{ $t(filter.label) }}
          </template>

          <StrapiBlocksText :nodes="filter.tooltip" />
        </LazyUiDialog>
      </template>

      <IconMin
        class="collapsable__icon icon icon--min"
      />
    </template>

    <LazyFilterLinks
      v-if="['cat', 'category'].includes(filter.key)"
      :filter="filter"
    />
    <LazyFilterRange
      v-else-if="filter.type === 'range'"
      :filter="filter"
      @filter-reset="filterResetAndUpdate(filter.key)"
      @filter-apply="filterApplyAndUpdate(filter.key)"
      @input="getExpectedTotal"
    />
    <LazyFilterBrands
      v-else-if="filter.key === 'k__tra_brand'"
      :filter="filter"
      @option-toggle="(optionKey: string) => toggle(filter.key, optionKey)"
      @filter-reset="filterResetAndUpdate(filter.key)"
      @filter-apply="filterApplyAndUpdate(filter.key)"
    />
    <LazyFilterOptions
      v-else-if="filter.type === 'default' || filter.type === 'swatch'"
      :filter="filter"
      @option-toggle="(optionKey: string) => toggle(filter.key, optionKey)"
      @filter-reset="filterResetAndUpdate(filter.key)"
      @filter-apply="filterApplyAndUpdate(filter.key)"
    />
    <dev-only v-else>
      <pre>{{ filter }}</pre>
    </dev-only>
  </UiCollapsable>
</template>

<script setup lang="ts">
const props = defineProps<{
  filter: any
}>()

const meta = inject('meta', ref())

const tooltip = useUiDialog(`tooltip__${props.filter.key}`)

const {
  optionToggle,
  filterApply,
  filterRemove,
  filtersApplyAll,
  getExpectedTotal,
} = useCatalogFilters(meta)

function toggle(filterKey: any, optionKey: any) {
  optionToggle(filterKey, optionKey)
  getExpectedTotal()
}

function filterApplyAndUpdate(filterKey: string) {
  filterApply(filterKey)
  filtersApplyAll()
}

function filterResetAndUpdate(filterKey: string) {
  filterRemove(filterKey) // Empty all active option in this filter
  getExpectedTotal()
  filtersApplyAll()
}
</script>

<style src="~layers/app/components/Filter/Item.css" />
