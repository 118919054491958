<template>
  <div
    v-if="filters?.length"
    class="filter-active"
  >
    <ol class="filter-active__list">
      <li class="filter-active__item">
        <button
          type="button"
          class="button button--link filter-active__clear"
          @click="$emit('filters-reset-all')"
        >
          <span>{{ $t('Clear All') }}</span>
        </button>
      </li>

      <li
        v-for="filter in filters"
        :key="filter.key"
        class="filter-active__item"
      >
        <div class="filter-active__filter">
          <span class="filter-active__label">{{ $t(filter.label) }}:</span>

          <template v-if="filter.type === 'range'">
            <button
              v-if="filter.minSelected"
              type="button"
              class="button button--link filter-active__link"
              @click="$emit('filter-reset-range-min', filter.key)"
            >
              <span>{{ filter.minSelected }}</span>
              <IconClose class="icon icon--close" />
            </button>

            <button
              v-if="filter.maxSelected"
              type="button"
              class="button button--link filter-active__link"
              @click="$emit('filter-reset-range-max', filter.key)"
            >
              <span>{{ filter.maxSelected }}</span>
              <IconClose class="icon icon--close" />
            </button>
          </template>

          <template v-else-if="filter.options?.length">
            <button
              v-for="option in filter.options"
              :key="option.key"
              type="button"
              class="button button--link filter-active__link"
              @click="$emit('option-toggle', filter.key, option.key)"
            >
              <span>{{ option.label }}</span>
              <IconClose class="icon icon--close" />
            </button>
          </template>
        </div>
      </li>
    </ol>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  activeFilters: any
}>()

defineEmits(['filters-reset-all', 'filter-reset-range-min', 'filter-reset-range-max', 'option-toggle'])

const filters = computed(() => {
  return props.activeFilters.filter(filter => !['cat'].includes(filter.key))
})
</script>

<style src="~layers/app/components/Filter/Active.css" />
