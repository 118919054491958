<template>
  <div class="filter-list">
    <div class="filter-list__sticky">
      <button
        type="button"
        class="button button--center button--cta filter-list__toggle"
        @click="openPanel"
      >
        <IconFilter class="icon icon--filter" />
        <span>{{ $t('Filter & Sort') }} <small class="filter-list__count">{{ extendedActiveFiltersCount }}</small></span>
      </button>
    </div>

    <div
      :class="{ 'backdrop--active': open }"
      class="backdrop"
      @click="resetAllAndClose"
    />

    <search
      :class="{ 'filter-list__panel--open': open }"
      class="filter-list__panel"
    >
      <div class="filter-list__title">
        <button
          type="button"
          class="button--link filter-list__close"
          @click="resetAllAndClose"
        >
          <span>{{ $t('Close') }}</span>
        </button>
      </div>

      <!-- <UiScrollable :enabled="enableScrolling.value" class="filter-list__others filter-list__scrollable" axis="y"> -->
      <UiScrollable
        :enabled="true"
        class="filter-list__others filter-list__scrollable"
        axis="y"
      >
        <PaginatorToolbar
          :sort="sort"
          :sort-options="sortOptions"
          @update-sort="updateSort"
        />

        <FilterActive
          v-if="extendedActiveFiltersCount"
          class="plp__filter-active"
          :active-filters="extendedActiveFilters"
          @filters-reset-all="resetAndApplyAll"
          @filter-reset-range-min="resetMin"
          @filter-reset-range-max="resetMax"
          @option-toggle="toggleActive"
        />

        <FilterItem
          v-for="filter in extendedFilters"
          :key="filter.key"
          :filter="filter"
        />
      </UiScrollable>

      <div
        v-if="!applyOptionsDirectly"
        class="filter-list__buttons"
      >
        <button
          type="button"
          class="filter-list__apply-all button button--cta button--full"
          @click="applyAllAndClose"
        >
          <span>{{ $t('Show') }} {{ $t('items', { quantity: expectedTotal }, expectedTotal) }}</span>
        </button>
      </div>
    </search>
  </div>
</template>

<script lang="ts" setup>
const { vuBreakpoints } = useApp()
const { sort, sortOptions, updateSort } = await useCatalogPaginator()

const enableScrolling = ref(false)
const meta = inject('meta', ref())
const tooltips = inject('tooltips', ref())

const {
  filterRangeResetMin,
  filterRangeResetMax,
  filterApply,
  optionToggle,
  activeFilters,
  applyOptionsDirectly,
  expectedTotal,
  filters,
  filtersApplyAll,
  filtersRemoveAll,
} = useCatalogFilters(meta)

const {
  open,
  openPanel,
  closePanel,
} = useCatalogFiltersModal()

const extendedFilters = computed(() => {
  return filters.value.map((filter) => {
    return {
      ...filter,
      tooltip: tooltips.value?.find(tip => tip.MagentoAttributeCode === filter.attribute)?.Richtext,
    }
  }).filter((filter: never) => !meta.value?.filters?.some((item: never) => item.key === filter.key))
})

const extendedActiveFilters = computed(() =>
  activeFilters.value.filter((filter: never) => !meta.value?.filters?.some((item: never) => item.key === filter.key)),
)

const extendedActiveFiltersCount = computed(() =>
  extendedActiveFilters.value.length,
)

onMounted(() => {
  enableScrolling.value = vuBreakpoints.smallerOrEqual('phablet').value

  watchEffect(() => {
    applyOptionsDirectly.value = vuBreakpoints.greaterOrEqual('desktop').value
  })
})

function applyAllAndClose() {
  filtersApplyAll()
  closePanel()
}

function resetAllAndClose() {
  closePanel()
  filtersRemoveAll()
}

function toggleActive(filterKey: string, optionKey: string) {
  optionToggle(filterKey, optionKey)
  filterApply(filterKey)
}

function resetMin(filterKey: string) {
  filterRangeResetMin(filterKey)
  filterApply(filterKey)
}

function resetMax(filterKey: string) {
  filterRangeResetMax(filterKey)
  filterApply(filterKey)
}

function resetAndApplyAll() {
  filtersRemoveAll()
  filtersApplyAll()
}
</script>

<style src="~layers/app/components/Filter/List.css" />
